const Tell = ({ ...props }) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M6.58009 2.5C6.91336 2.5 7.22044 2.68077 7.38223 2.97216L8.50447 4.99369C8.6514 5.25838 8.65833 5.57853 8.52291 5.84932L7.44178 8.01159C7.44178 8.01159 7.75509 9.62243 9.06632 10.9337C10.3776 12.2449 11.983 12.5528 11.983 12.5528L14.1449 11.4718C14.4159 11.3363 14.7363 11.3434 15.0011 11.4906L17.0283 12.6177C17.3195 12.7795 17.5 13.0864 17.5 13.4195V15.7469C17.5 16.9321 16.3991 17.7882 15.2761 17.4092C12.9696 16.631 9.38936 15.1492 7.12011 12.8799C4.85082 10.6106 3.36902 7.03037 2.59078 4.72391C2.21186 3.60089 3.06789 2.5 4.25309 2.5H6.58009Z"
        stroke="#999999"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M18.2699 8.33317C18.3828 7.57579 18.3458 6.80041 18.1588 6.05487C17.9119 5.06998 17.4033 4.13726 16.6331 3.36706C15.8629 2.59687 14.9302 2.08829 13.9453 1.84134C13.1998 1.65439 12.4244 1.61737 11.667 1.73027"
        stroke="#999999"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.9684 8.33333C15.0995 7.4544 14.8267 6.52688 14.1501 5.85029C13.4734 5.17369 12.5459 4.90086 11.667 5.03188"
        stroke="#999999"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Tell
